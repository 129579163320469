export const getFavorites = () => {
  const data = localStorage.getItem("favorites");
  return data ? JSON.parse(data) : [];
};

export const addFavorite = (userEmail, sourceId) => {
  const favorites = getFavorites();
  const favorite = favorites.find(
    (item) =>
      item.user_email === userEmail && item.favorite_source_id === sourceId
  );
  if (!favorite) {
    favorites.push({
      user_email: userEmail,
      favorite_source_id: sourceId,
    });
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }
};

export const removeFavorite = (userEmail, sourceId) => {
  const favorites = getFavorites();
  const updatedFavorites = favorites.filter(
    (item) =>
      item.user_email === userEmail && item.favorite_source_id !== sourceId
  );
  localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
};

export const isFavorite = (userEmail, sourceId) => {
  const favorites = getFavorites();
  const index = favorites.findIndex(
    (item) =>
      item.user_email === userEmail && item.favorite_source_id === sourceId
  );
  return index !== -1;
};
