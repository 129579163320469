import React, { FunctionComponent } from "react";
import Modal from "antd/es/modal";
import "./result.css";

interface ModalProps {
  visible: boolean;
  onCancel: any;
  onOk: any;
  source: string;
}

const ShareModal: FunctionComponent<ModalProps> = (props) => {
  const copyLink = () => {
    window.navigator["clipboard"]
      .writeText(props.source)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const shareWa = () => {
    const source = `https://wa.me/?text=${encodeURIComponent(
      "Hi, take a look this room"
    )}%20${encodeURIComponent(props.source)}`;
    window.open(source, "_blank");
  };

  const shareFb = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      props.source
    )}`;
    window.open(facebookShareUrl, "_blank");
  };

  return (
    <div>
      <Modal
        width={350}
        title="Share Listing"
        visible={props.visible}
        footer={null}
        wrapClassName="navigation-modal"
        closeIcon={<span onClick={props.onCancel}>X</span>}
      >
        <div className="share-icon-wrapper">
          <div onClick={copyLink} className="pointer text-center">
            <img
              src={require("../../../assets/images/copy-link.png")}
              width="40"
            />
            <div>Copy Link</div>
          </div>
          <div onClick={shareFb} className="pointer text-center">
            <img
              src={require("../../../assets/images/share-fb.png")}
              width="40"
            />
            <div>Facebook</div>
          </div>
          <div onClick={shareWa} className="pointer text-center">
            <img
              src={require("../../../assets/images/share-wa.png")}
              width="40"
            />
            <div>WhatsApp</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ShareModal;
