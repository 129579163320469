import React, { FunctionComponent, useState, useEffect } from "react";
import cookie from "react-cookies";
import Row from "antd/es/row";
import Col from "antd/es/col";
import TimeHistoryIcon from "../../../components/icons/TimeHistory";
import ShareIcon from "../../../components/icons/Share";
import EmptyHeartIcon from "../../../components/icons/EmptyHeart";
import RedHeartIcon from "../../../components/icons/RedHeart";
import moment from "moment";
import { getSourceIcon } from "../../../utilities";
import { addFavorite, removeFavorite } from "../../../api/favorites";
import { useUser } from "../../../store/user/context";

interface RoomProps {
  sourceId: number;
  price: string;
  address: String;
  image: string;
  href: string;
  datePublished: string;
  source: string;
  isMobile?: boolean;
  showModal: Function;
  shareModal?: Function;
  isFavorite?: boolean;
}

const getTimeAgo = (input: string) => {
  return moment(input).fromNow();
};

const RoomList: FunctionComponent<RoomProps> = (props) => {
  const [isFavoriteRoom, setFavoriteRoom] = useState(false);
  const isLoggedIn = cookie.load("rentalios-email") ? true : false;
  const userEmail = cookie.load("rentalios-email");

  const imageSource = props.image
    ? props.image
    : require("../../../assets/images/img_placeholder.png");

  const shareProperty = () => {
    if (props.shareModal) {
      props.shareModal(props.href);
    }
  };

  const setFavorite = () => {
    if (isLoggedIn && userEmail) {
      if (isFavoriteRoom) {
        setFavoriteRoom(false);
        removeFavorite(userEmail, props.sourceId);
      } else {
        setFavoriteRoom(true);
        addFavorite(userEmail, props.sourceId);
      }
    }
  };

  useEffect(() => {
    if (props.isFavorite) {
      setFavoriteRoom(true);
    }
  }, []);

  return (
    <div className="room-wrapper">
      <Row>
        <Col span={props.isMobile ? 24 : 12}>
          <div
            style={{
              height: props.isMobile ? "280px" : "150px",
              position: "relative",
              backgroundSize: "cover",
              backgroundImage: `url(${
                props.image
                  ? props.image
                  : require("../../../assets/images/img_placeholder.png")
              })`,
            }}
          >
            <div className="room-source-icon-wrapper">
              {getSourceIcon(props.source, true)}
            </div>
          </div>
        </Col>
        <Col span={props.isMobile ? 24 : 12} style={{ textAlign: "left" }}>
          <div>
            <div className="icon-wrapper">
              <div>
                <TimeHistoryIcon /> {getTimeAgo(props.datePublished)}
              </div>
              <div style={{ position: "absolute", right: 0 }}>
                <span
                  onClick={() => shareProperty()}
                  style={{ marginRight: "16px", cursor: "pointer" }}
                >
                  <ShareIcon />
                </span>
                {isLoggedIn && userEmail ? (
                  <span
                    onClick={() => setFavorite()}
                    style={{ cursor: "pointer" }}
                  >
                    {isFavoriteRoom ? (
                      <RedHeartIcon color="red" />
                    ) : (
                      <EmptyHeartIcon />
                    )}
                  </span>
                ) : (
                  <span />
                )}
              </div>
            </div>
            <div className="price-wrapper">
              <span>{props.price}</span>
            </div>
            <div className="address-wrapper">
              <span>{props.address}</span>
            </div>
            <div className="contact-property-wrapper">
              <button
                onClick={(e) => props.showModal(props.href)}
                style={{ width: "100%" }}
                className="btn-estimate-rent"
              >
                Contact Property
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default RoomList;
